const colors = {
  lightBlue: "#9fb4bd",
  darkBlue: "#3d6170",
  lightPurple: "#d4d3dc",
  darkPurple: "#6f6c8c",
  green: "#79a38c",
  white: "#fff",
}

export default colors
