import React, { useState } from "react"
import MediaItem from "../../pages/long-term-care-info/MediaItem"
import { Button } from "semantic-ui-react"

export default function Posts({ data }) {
  const [count, setCount] = useState(3)

  let filteredItems = data.slice(0, count)

  return (
    <div className="footer-media">
      <div className="media-items">
        {filteredItems.map(({ node, i }) => (
          <MediaItem
            key={i}
            date={new Date(node.frontmatter.date).toLocaleDateString()}
            slug={node.fields.slug}
            title={node.frontmatter.title}
            image={node.frontmatter.previewImage}
            description={node.frontmatter.description}
            posttype={node.frontmatter.posttype}
          />
        ))}
      </div>
      {count < data.length && (
        <Button
          onClick={() => setCount(count + 3)}
          color="base"
          content="Load More"
        />
      )}
    </div>
  )
}
