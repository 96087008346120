/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "../footer/"
import Header from "../header"
import "./layout.css"

const PageLayout = ({ children, background, showBlog }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={{ backgroundColor: background }}>
        <main>{children}</main>
      </div>
      <Footer showBlog={showBlog} />
    </div>
  )
}

PageLayout.defaultProps = {
  background: "#fff",
  showBlog: true,
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageLayout
