import { Link, useStaticQuery, graphql } from "gatsby"
import { Dropdown } from "semantic-ui-react"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import "./index.scss"
import BurgerMenu from "./BurgerMenu"
import { IoLogoFacebook, IoLogoLinkedin, IoLogoYoutube } from "react-icons/io";

import colors from "../../colors"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const buttonIconProps = {
  size: "90px",
}

// const homeownerLinks = [
//   {
//     text: "Air Conditioner Financing for Homeowners",
//     as: Link,
//     to: "/air-conditioner-financing",
//   },
//   {
//     text: "Rent-to-Own Air Conditioner",
//     as: Link,
//     to: "/rent-to-own-air-conditioner",
//   },
//   { text: "Furnace Rent-to-Own", as: Link, to: "/furnace-rent-to-own" },
//   {
//     text: "HVAC Financing Bad Credit Alternative",
//     as: Link,
//     to: "/hvac-financing-bad-credit",
//   },
//   {
//     text: "No Credit Check HVAC Financing Alternative",
//     as: Link,
//     to: "/no-credit-check-hvac-financing",
//   },
//   {
//     text: "Financing For HVAC Replacement Alternative",
//     as: Link,
//     to: "/financing-for-hvac-replacement",
//   },
//   {
//     text: "HVAC Unit Financing Alternative",
//     as: Link,
//     to: "/hvac-unit-financing",
//   },
// ]
//
// const dealerLinks = [
//   {
//     text: "HVAC Financing For Dealers",
//     as: Link,
//     to: "/hvac-financing",
//   },
//   {
//     text: "Carrier HVAC Financing Alternative",
//     as: Link,
//     to: "/carrier-hvac-financing",
//   },
//   { text: "HVAC Leasing Alternative", as: Link, to: "/hvac-leasing" },
//   {
//     text: " Rent-to-Own Heating and Air",
//     as: Link,
//     to: "/rent-to-own-heating-air",
//   },
// ]

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(
        limit: 4
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `)

  let blogLinks = []

  data.allMarkdownRemark.edges.forEach(({ node }) => {
    blogLinks.push({
      text: node.frontmatter.title,
      as: Link,
      to: `${node.fields.slug}`,
    })
  })

  blogLinks.push({
    text: "Older Posts",
    as: Link,
    to: `/long-term-care-articles/`,
  })

  const links = [
    { text: "Home", url: "/" },
    { text: "What Is LTC?", url: "/long-term-health-care/" },
    { text: "LTC Planning", url: "/long-term-care-planning/" },
    { text: "Why Use A Specialist?", url: "/long-term-care-insurance-specialist/" },
    { text: "Get A Quote", url: "/long-term-care-insurance-quote/" },
    { text: "Employee Benefits", url: "/long-term-care-group-insurance/" },
  ]

  return (
    <React.Fragment>
      <BurgerMenu
        topLinks={[
          ...links,
          { text: "Free Webinar", url: "/long-term-care-webinar/" },
          { text: "Schedule Consultation", url: "https://calendly.com/corey-14/30min" },
        ]}
      />
      <header className="site-header">
        <div className="wrapper">
          <div className="wrappers">
            <div className="top-wrapper">
              <div className="top-left">
                <div className="logo-wrapper">
                  <Link to="/">
                    <img src={`/images/logo.png`} />
                  </Link>
                </div>
                <div className="slogan">
                  Experienced Guidance. Trusted Solutions.
                </div>
              </div>
              <div className="top-right non-semantic-protector">
                <div className="ribbon">
                  <span className="ribbon-content"><a href="/long-term-care-webinar/">Free Webinar</a> | <a href="https://calendly.com/corey-14/30min" target="_blank">Schedule Visit</a> | <a href="tel:6788145088">Call (678) 814-5088</a></span>
                </div>
                <div className="social">
                  <ul>
                    <li><Link href="https://www.youtube.com/channel/UCXmPIHY2Uei540uCydNVebg" target="_blank"><IoLogoYoutube /></Link></li>
                    <li><Link href="https://www.linkedin.com/in/coreyrieck/" target="_blank"><IoLogoLinkedin /></Link></li>
                    <li><Link href="https://www.facebook.com/theltcplangroup" target="_blank"><IoLogoFacebook/></Link></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bottom-wrapper">
              <div className="links">
                {links.map((l, i) =>
                  l.dropdown ? (
                    <Dropdown
                      text={l.text}
                      options={l.links}
                      simple
                      item
                      to={l.url}
                      as={Link}
                    />
                  ) : (
                    <Link key={i} to={l.url}>
                      {l.text}
                    </Link>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
