import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

import Posts from "./Posts"

import "./index.scss"

const Footer = ({ showBlog }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { posttype: { ne: "person" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
              date
              previewImage
              posttype
            }
          }
        }
      }
    }
  `)

  return (
    <footer>
      {showBlog && <Posts data={data.allMarkdownRemark.edges} />}
      <div className="wrapper">
        <div className="footer-links">
          <div className="footer-link-section">
            <h3>Get Started</h3>
            <a href="/long-term-care-webinar/">Free Webinar</a>
            <a href="/long-term-care-insurance-quote/">Get A Quote</a>
            <a href="https://calendly.com/corey-14/30min" target="_blank">
              Schedule Consultation
            </a>
          </div>
          <div className="footer-link-section">
            <h3>Learn More</h3>
            <a href="/long-term-health-care/">What Is LTC?</a>
            <a href="/long-term-care-insurance-companies/">
              LTC Insurance Companies
            </a>
            <a href="/long-term-care-planning/">LTC Planning</a>
            <a href="/long-term-care-insurance-cost/">LTC Insurance Cost</a>
            <a href="/long-term-care-insurance-specialist/">
              Why Use A Specialist?
            </a>
            <a href="/long-term-care-facts-myths/">LTC Facts &amp; Myths</a>
          </div>
          <div className="footer-link-section">
            <h3>Employers</h3>
            <a href="/long-term-care-group-insurance/">Employee Benefit Plans</a>
          </div>
          <div className="footer-link-section">
            <h3>Media</h3>
            <a href="/long-term-care-info/">All</a>
            <a href="/long-term-care-articles/">Articles</a>
            <a href="/long-term-care-insurance-advice-videos/">Videos</a>
            <a href="/long-term-care-insurance-providers-podcast/">Podcast</a>
          </div>
          <div className="footer-link-section">
            <h3>Company</h3>
            <a href="/">Home</a>
            <a href="/about/">About</a>
          </div>
        </div>
        <div className="footer-logo">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} />
        </div>
      </div>
      <p className="copyright-links">
        © {new Date().getFullYear()} The Long Term Care Planning Group. All
        Rights Reserved.
        <br />
        <a href="https://goo.gl/maps/u1ZE82DPatz6Qupt9" target="_blank">
          1000 Circle 75 Parkway SE Suite 44, Atlanta, GA 30339
        </a>
        <br />
        <a href="tel:6788145088">(678) 814-5088</a>
        <br />
        <a href="/privacy-policy/">Privacy Policy</a> - <a href="https://websuasion.com/content-marketing-strategy/">Content Marketing Strategy</a> by <a href="https://websuasion.com/">websuasion</a>
      </p>
    </footer>
  )
}

export default Footer

Footer.defaultProps = {
  showBlog: true,
}
